@import '@style/variable.less';

.app {
  article {
    background-color: @light;
    &:not(.is-touch) {
      border-radius: 3rem 0 0 3rem;
    }
    header {
      height: 3.8rem;
      &.is-touch {
        background-color: @white;
        box-shadow: 0 0 0.5rem rgba(26, 29, 62, 0.1);
      }
    }
  }
}

@primary-color: #5c70f0;@error-color: #e87065;