@import './variable.less';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  list-style: none;
  text-decoration: none;
  user-select: none;
}

html {
  font-size: 16px;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: @black;
}
